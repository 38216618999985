import { Helmet } from "react-helmet";
import React from "react";

import CallToAction from "../components/call-to-action";
import Header from "../components/header";
import Footer from "../components/footer";

const PrivacyPage = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - StandupWizard </title>
      </Helmet>
      <div className="pt-6">
        <Header />
        <div className="relative py-24 px-4 overflow-hidden">
          <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">
            Privacy Policy
          </h1>
          <div className="mt-16 text-lg max-w-prose mx-auto">
            <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
              <p>Effective date: 05/15/2021</p>
              <h3>1. Introduction</h3>
              <p>
                Welcome to <b>StandupWizard</b>.
              </p>
              <p>
                <b>Sidewave Digital Inc.</b> (“us”, “we”, or “our”) operates
                https://standupwizard.com (hereinafter referred to as “
                <b>Service</b>”).
              </p>
              <p>
                Our Privacy Policy governs your visit to
                https://standupwizard.com, and explains how we collect,
                safeguard and disclose information that results from your use of
                our Service.
              </p>
              <p>
                We use your data to provide and improve Service. By using
                Service, you agree to the collection and use of information in
                accordance with this policy. Unless otherwise defined in this
                Privacy Policy, the terms used in this Privacy Policy have the
                same meanings as in our Terms and Conditions.
              </p>
              <p>
                Our Terms and Conditions (“<b>Terms</b>”) govern all use of our
                Service and together with the Privacy Policy constitutes your
                agreement with us (“<b>agreement</b>”).
              </p>
              <h3>2. Definitions</h3>
              <p>
                <b>SERVICE</b> means the https://standupwizard.com website
                operated by Sidewave Digital Inc.
              </p>
              <p>
                <b>PERSONAL DATA</b> means data about a living individual who
                can be identified from those data (or from those and other
                information either in our possession or likely to come into our
                possession).
              </p>
              <p>
                <b>USAGE DATA</b> is data collected automatically either
                generated by the use of Service or from Service infrastructure
                itself (for example, the duration of a page visit).
              </p>
              <p>
                <b>COOKIES</b> are small files stored on your device (computer
                or mobile device).
              </p>
              <p>
                <b>DATA CONTROLLER</b> means a natural or legal person who
                (either alone or jointly or in common with other persons)
                determines the purposes for which and the manner in which any
                personal data are, or are to be, processed. For the purpose of
                this Privacy Policy, we are a Data Controller of your data.
              </p>
              <p>
                <b>DATA PROCESSORS</b> (OR SERVICE PROVIDERS) means any natural
                or legal person who processes the data on behalf of the Data
                Controller. We may use the services of various Service Providers
                in order to process your data more effectively.
              </p>
              <p>
                <b>DATA SUBJECT</b> is any living individual who is the subject
                of Personal Data.
              </p>
              <p>
                <b>THE USER</b> is the individual using our Service. The User
                corresponds to the Data Subject, who is the subject of Personal
                Data.
              </p>
              <h3>3. Information Collection and Use</h3>
              <p>
                We collect several different types of information for various
                purposes to provide and improve our Service to you.
              </p>
              <h3>4. Types of Data Collected</h3>
              <p>
                <b>Personal Data</b>
                <br />
                While using our Service, we may ask you to provide us with
                certain personally identifiable information that can be used to
                contact or identify you (“<b>Personal Data</b>”). Personally
                identifiable information may include, but is not limited to:
              </p>
              <ol type="a">
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Cookies and Usage Data</li>
              </ol>
              <p>
                We may use your Personal Data to contact you with newsletters,
                marketing or promotional materials and other information that
                may be of interest to you or your account. Email addresses will
                only be used in relation to our product. You may also opt out of receiving any, or all,
                of these communications from us by following the unsubscribe
                link or by emailing at support[at]standupwizard.com.
              </p>
              <p>
                <b>Usage Data</b>
                <br />
                We may also collect information that your browser sends whenever
                you visit our Service or when you access Service by or through a
                mobile device (“<b>Usage Data</b>”).
              </p>
              <p>
                This Usage Data may include information such as your computer's
                Internet Protocol address (e.g. IP address), browser type,
                browser version, the pages of our Service that you visit, the
                time and date of your visit, the time spent on those pages,
                unique device identifiers and other diagnostic data.
              </p>
              <p>
                When you access Service with a mobile device, this Usage Data
                may include information such as the type of mobile device you
                use, your mobile device unique ID, the IP address of your mobile
                device, your mobile operating system, the type of mobile
                Internet browser you use, unique device identifiers and other
                diagnostic data.
              </p>
              <p>
                <b>Tracking Cookies Data</b>
                <br />
                We use cookies and similar tracking technologies to track the
                activity on our Service and we hold certain information.
              </p>
              <p>
                Cookies are files with a small amount of data which may include
                an anonymous unique identifier. Cookies are sent to your browser
                from a website and stored on your device. Other tracking
                technologies are also used such as beacons, tags and scripts to
                collect and track information and to improve and analyze our
                Service.
              </p>
              <p>
                You can instruct your browser to refuse all cookies or to
                indicate when a cookie is being sent. However, if you do not
                accept cookies, you may not be able to use some portions of our
                Service.
              </p>
              <p>Examples of Cookies we use:</p>
              <ol type="a">
                <li>
                  Session Cookies: We use Session Cookies to operate our
                  Service.
                </li>
                <li>
                  Preference Cookies: We use Preference Cookies to remember your
                  preferences and various settings.
                </li>
                <li>
                  Security Cookies: We use Security Cookies for security
                  purposes.
                </li>
              </ol>
              <h3>5. Use of Data</h3>
              <p>We use the collected data for various purposes:</p>
              <ol type="a">
                <li>to provide and maintain our Service;</li>
                <li>to notify you about changes to our Service;</li>
                <li>
                  to allow you to participate in interactive features of our
                  Service when you choose to do so;
                </li>
                <li>to provide customer support;</li>
                <li>
                  to gather analysis or valuable information so that we can
                  improve our Service;
                </li>
                <li>to monitor the usage of our Service;</li>
                <li>to detect, prevent and address technical issues;</li>
                <li>to fulfill any other purpose for which you provide it;</li>
                <li>
                  to carry out our obligations and enforce our rights arising
                  from any contracts entered into between you and us, including
                  for billing and collection;
                </li>
                <li>
                  to provide you with notices about your account and/or
                  subscription, including expiration and renewal notices,
                  email-instructions, etc. unless you have opted not to receive
                  such information;
                </li>
                <li>
                  to provide you with news, special offers and general
                  information about other goods, services and events which we
                  offer that are similar to those that you have already
                  purchased or enquired about unless you have opted not to
                  receive such information;
                </li>
                <li>
                  in any other way we may describe when you provide the
                  information;
                </li>
                <li>for any other purpose with your consent.</li>
              </ol>
              <h3>6. Retention of Data</h3>
              <p>
                We will retain your Personal Data only for as long as is
                necessary for the purposes set out in this Privacy Policy. We
                will retain and use your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
              </p>
              <p>
                We will also retain Usage Data for internal analysis purposes.
                Usage Data is generally retained for a shorter period, except
                when this data is used to strengthen the security or to improve
                the functionality of our Service, or we are legally obligated to
                retain this data for longer time periods.
              </p>
              <h3>7. Transfer of Data</h3>
              <p>
                Your information, including Personal Data, may be transferred to
                - and maintained on - computers located outside of your state,
                province, country or other governmental jurisdiction where the
                data protection laws may differ from those of your jurisdiction.
              </p>
              <p>
                If you are located outside United States and choose to provide
                information to us, please note that we transfer the data,
                including Personal Data, to United States and process it there.
              </p>
              <p>
                Your consent to this Privacy Policy followed by your submission
                of such information represents your agreement to that transfer.
              </p>
              <p>
                We will take all the steps reasonably necessary to ensure that
                your data is treated securely and in accordance with this
                Privacy Policy and no transfer of your Personal Data will take
                place to an organisation or a country unless there are adequate
                controls in place including the security of your data and other
                personal information.
              </p>
              <h3>8. Disclosure of Data</h3>
              <p>
                We may disclose personal information that we collect, or you
                provide:
              </p>
              <ol type="a">
                <li>
                  <b>Disclosure for Law Enforcement.</b>
                  <br />
                  Under certain circumstances, we may be required to disclose
                  your Personal Data if required to do so by law or in response
                  to valid requests by public authorities.
                </li>
                <li>
                  <b>Business Transaction.</b>
                  <br />
                  If we or our subsidiaries are involved in a merger,
                  acquisition or asset sale, your Personal Data may be
                  transferred.
                </li>
                <li>
                  <b>Other cases. We may disclose your information also:</b>
                  <br />
                  <ol type="i">
                    <li>to fulfill the purpose for which you provide it;</li>
                    <li>
                      for the purpose of including your company's logo on our
                      website;
                    </li>
                    <li>
                      for any other purpose disclosed by us when you provide the
                      information;
                    </li>
                    <li>with your consent in any other cases;</li>
                    <li>
                      if we believe disclosure is necessary or appropriate to
                      protect the rights, property, or safety of the Company,
                      our customers, or others.
                    </li>
                  </ol>
                </li>
              </ol>
              <h3>9. Security of Data</h3>
              <p>
                The security of your data is important to us but remember that
                no method of transmission over the Internet or method of
                electronic storage is 100% secure. While we strive to use
                commercially acceptable means to protect your Personal Data, we
                cannot guarantee its absolute security.
              </p>
              <h3>
                10. Your Data Protection Rights Under General Data Protection
                Regulation (GDPR)
              </h3>
              <p>
                If you are a resident of the European Union (EU) and European
                Economic Area (EEA), you have certain data protection rights,
                covered by GDPR. – See more at
                https://eur-lex.europa.eu/eli/reg/2016/679/oj{" "}
              </p>
              <p>
                We aim to take reasonable steps to allow you to correct, amend,
                delete, or limit the use of your Personal Data.
              </p>
              <p>
                If you wish to be informed what Personal Data we hold about you
                and if you want it to be removed from our systems, please email
                us at support[at]standupwizard.com.{" "}
              </p>
              <p>
                In certain circumstances, you have the following data protection
                rights:
              </p>
              <ol type="a">
                <li>
                  the right to access, update or to delete the information we
                  have on you;
                </li>
                <li>
                  the right of rectification. You have the right to have your
                  information rectified if that information is inaccurate or
                  incomplete;
                </li>
                <li>
                  the right to object. You have the right to object to our
                  processing of your Personal Data;
                </li>
                <li>
                  the right of restriction. You have the right to request that
                  we restrict the processing of your personal information;
                </li>
                <li>
                  the right to data portability. You have the right to be
                  provided with a copy of your Personal Data in a structured,
                  machine-readable and commonly used format;
                </li>
                <li>
                  the right to withdraw consent. You also have the right to
                  withdraw your consent at any time where we rely on your
                  consent to process your personal information;
                </li>
              </ol>
              <p>
                Please note that we may ask you to verify your identity before
                responding to such requests. Please note, we may not able to
                provide Service without some necessary data.
              </p>
              <p>
                You have the right to complain to a Data Protection Authority
                about our collection and use of your Personal Data. For more
                information, please contact your local data protection authority
                in the European Economic Area (EEA).
              </p>
              <h3>
                11. Your Data Protection Rights under the California Privacy
                Protection Act (CalOPPA)
              </h3>
              <p>
                CalOPPA is the first state law in the nation to require
                commercial websites and online services to post a privacy
                policy. The law’s reach stretches well beyond California to
                require a person or company in the United States (and
                conceivable the world) that operates websites collecting
                personally identifiable information from California consumers to
                post a conspicuous privacy policy on its website stating exactly
                the information being collected and those individuals with whom
                it is being shared, and to comply with this policy. – See more
                at:
                https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/{" "}
              </p>
              <p>According to CalOPPA we agree to the following:</p>
              <ol type="a">
                <li>users can visit our site anonymously;</li>
                <li>
                  our Privacy Policy link includes the word “Privacy”, and can
                  easily be found on the page specified above on the home page
                  of our website;
                </li>
                <li>
                  users will be notified of any privacy policy changes on our
                  Privacy Policy Page;
                </li>
                <li>
                  users are able to change their personal information by
                  emailing us at support[at]standupwizard.com.{" "}
                </li>
              </ol>
              <h3>12. Service Providers</h3>
              <p>
                We may employ third party companies and individuals to
                facilitate our Service (“<b>Service Providers</b>”), provide
                Service on our behalf, perform Service-related services or
                assist us in analysing how our Service is used.
              </p>
              <p>
                These third parties have access to your Personal Data only to
                perform these tasks on our behalf and are obligated not to
                disclose or use it for any other purpose.
              </p>
              <h3>13. Analytics</h3>
              <p>
                We may use third-party Service Providers to monitor and analyze
                the use of our Service.
              </p>
              <p>
                <b>Plausible Analytics</b>
                <br />
                Plausible is intuitive, lightweight and open source web
                analytics. No cookies and fully compliant with GDPR, CCPA and
                PECR.
              </p>
              <p>
                For more information on the privacy practices of Plausible,
                please visit the Privacy Terms web page:
                https://plausible.io/privacy
              </p>
              <h3>14. CI/CD tools</h3>
              <p>
                We may use third-party Service Providers to automate the
                development process of our Service.{" "}
              </p>
              <p>
                <b>GitHub</b>
                <br />
                GitHub is provided by GitHub, Inc.
              </p>
              <p>
                GitHub is a development platform to host and review code, manage
                projects, and build software.
              </p>
              <p>
                For more information on what data GitHub collects for what
                purpose and how the protection of the data is ensured, please
                visit GitHub Privacy Policy page:
                https://help.github.com/en/articles/github-privacy-statement.
              </p>
              <p>
                <b>CircleCI</b>
                <br />
                CircleCI is provided by Circle Internet Services, Inc.
              </p>
              <p>
                CircleCI is Continuous Integration, a development practice which
                is being used by software teams allowing them to build, test and
                deploy applications easier and quicker on multiple platforms.{" "}
              </p>
              <p>
                For more information on what data Circle CI collects for what
                purpose and how the protection of the data is ensured, please
                visit Circle CI Privacy Policy page:
                https://circleci.com/privacy/.{" "}
              </p>
              <h3>15. Behavioral Remarketing</h3>
              <p>
                We use remarketing services to advertise on third party websites
                to you after you visited our Service. We and our third-party
                vendors use cookies to inform, optimise and serve ads based on
                your past visits to our Service.
              </p>
              <p>
                <b>Google Ads (AdWords)</b>
                <br />
                Google Ads (AdWords) remarketing service is provided by Google
                Inc.
              </p>
              <p>
                You can opt-out of Google Analytics for Display Advertising and
                customize the Google Display Network ads by visiting the Google
                Ads Settings page: http://www.google.com/settings/ads
              </p>
              <p>
                Google also recommends installing the Google Analytics Opt-out
                Browser Add-on – https://tools.google.com/dlpage/gaoptout – for
                your web browser. Google Analytics Opt-out Browser Add-on
                provides visitors with the ability to prevent their data from
                being collected and used by Google Analytics.
              </p>
              <p>
                For more information on the privacy practices of Google, please
                visit the Google Privacy Terms web page:
                https://policies.google.com/privacy?hl=en
              </p>
              <p>
                <b>Facebook</b>
                <br />
                Facebook remarketing service is provided by Facebook Inc.
              </p>
              <p>
                You can learn more about interest-based advertising from
                Facebook by visiting this page:
                https://www.facebook.com/help/164968693837950
              </p>
              <p>
                To opt-out from Facebook's interest-based ads, follow these
                instructions from Facebook:
                https://www.facebook.com/help/568137493302217
              </p>
              <p>
                Facebook adheres to the Self-Regulatory Principles for Online
                Behavioural Advertising established by the Digital Advertising
                Alliance. You can also opt-out from Facebook and other
                participating companies through the Digital Advertising Alliance
                in the USA http://www.aboutads.info/choices/, the Digital
                Advertising Alliance of Canada in Canada
                http://youradchoices.ca/ or the European Interactive Digital
                Advertising Alliance in Europe http://www.youronlinechoices.eu/,
                or opt-out using your mobile device settings.
              </p>
              <p>
                For more information on the privacy practices of Facebook,
                please visit Facebook's Data Policy:
                https://www.facebook.com/privacy/explanation
              </p>
              <h3>16. Payments</h3>
              <p>
                We may provide paid products and/or services within Service. In
                that case, we use third-party services for payment processing
                (e.g. payment processors).
              </p>
              <p>
                We will not store or collect your payment card details. That
                information is provided directly to our third-party payment
                processors whose use of your personal information is governed by
                their Privacy Policy. These payment processors adhere to the
                standards set by PCI-DSS as managed by the PCI Security
                Standards Council, which is a joint effort of brands like Visa,
                Mastercard, American Express and Discover. PCI-DSS requirements
                help ensure the secure handling of payment information.
              </p>
              <p>The payment processors we work with are:</p>
              <p>
                <b>Stripe:</b>
                <br />
                Their Privacy Policy can be viewed at:
                https://stripe.com/us/privacy
              </p>
              <h3>17. Links to Other Sites</h3>
              <p>
                Our Service may contain links to other sites that are not
                operated by us. If you click a third party link, you will be
                directed to that third party's site. We strongly advise you to
                review the Privacy Policy of every site you visit.
              </p>
              <p>
                We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites
                or services.
              </p>
              <h3>18. Children's Privacy</h3>
              <p>
                Our Services are not intended for use by children under the age
                of 18 (“<b>Children</b>”).{" "}
              </p>
              <p>
                We do not knowingly collect personally identifiable information
                from Children under 18. If you become aware that a Child has
                provided us with Personal Data, please contact us. If we become
                aware that we have collected Personal Data from Children without
                verification of parental consent, we take steps to remove that
                information from our servers.
              </p>
              <h3>19. Changes to This Privacy Policy</h3>
              <p>
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page.
              </p>
              <p>
                We will let you know via email and/or a prominent notice on our
                Service, prior to the change becoming effective and update
                “effective date” at the top of this Privacy Policy.
              </p>
              <p>
                You are advised to review this Privacy Policy periodically for
                any changes. Changes to this Privacy Policy are effective when
                they are posted on this page.
              </p>
              <h3>20. Contact Us</h3>
              <p>
                If you have any questions about this Privacy Policy, please
                contact us:
              </p>
              <p>By email: support[at]standupwizard.com.</p>
            </div>
          </div>
        </div>
      </div>
      <CallToAction />
      <Footer />
    </>
  );
};

export default PrivacyPage;
